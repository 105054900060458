import {Injectable, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class AdminUtilService implements OnInit {

    userInfo: any = {};
    selectedLanguageName: string = 'English';
    selectedLanguage: string = 'en';

    constructor(private router: Router) {
    }

    ngOnInit() {

    }

    tokenExpired(code: number) {
        if (code === 401 || code === 403) {
            localStorage.removeItem(btoa('access_token'));
            localStorage.removeItem(btoa('user-info'));
            // this.router.navigate(['/login']);
        }
    }

    public logout() {
        localStorage.removeItem(btoa('access_token'));
        localStorage.removeItem(btoa('user-info'));

        this.router.navigate(['/login']);
    }

    userHasPermissionTo(action: string, permissionPathName: string) {
        if (this.userInfo && this.userInfo.permissions) {
            const [mainMenu, subMenu] = permissionPathName.split('.');
            if (!mainMenu) {
                return false;
            }
            for (const role in this.userInfo.permissions) {
                if (this.userInfo.permissions.hasOwnProperty(role)) {
                    const mainMenuPermissions = this.userInfo.permissions[role];
                    // console.log('role | ' + role + ' |' + mainMenu + '|' + subMenu, mainMenuPermissions);
                    if (mainMenuPermissions[mainMenu]) {
                        if (subMenu) {
                            if (mainMenuPermissions[mainMenu].submenu &&
                                mainMenuPermissions[mainMenu].submenu[subMenu] &&
                                mainMenuPermissions[mainMenu].submenu[subMenu][action]) {
                                return true;
                            }
                        } else if (mainMenuPermissions[mainMenu][action]) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    changeLayoutOrientation(lang: string, componentType: string): boolean {
        let isSuccess = false;
        const styleRTL: HTMLLinkElement = <HTMLLinkElement> document.getElementById('style-rtl');

        const mainHtml = document.getElementsByTagName('html');

        if (lang === 'en') {
            mainHtml[0].removeAttribute('dir');
            mainHtml[0].removeAttribute('lang');
            if (componentType === 'header') {
                styleRTL.href = '#';
            }
            this.selectedLanguage = 'en';
            this.selectedLanguageName = 'English';
            isSuccess = true;
        } else {
            let langAtt;
            // let dirAtt;
            // dirAtt = document.createAttribute('dir');
            // dirAtt.value = 'rtl';
            langAtt = document.createAttribute('lang');
            langAtt.value = 'ar';
            // mainHtml[0].setAttributeNode(dirAtt);
            // mainHtml[0].setAttributeNode(langAtt);
            if (componentType === 'header') {
                styleRTL.href = 'assets/css/style-rtl.css';
            }
            this.selectedLanguage = 'ar';
            this.selectedLanguageName = 'العربية';
            isSuccess = false;
        }
        return isSuccess;
    }

}
