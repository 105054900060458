/**
 * @Author Irfan Nasim
 * @since 24-Jun-2020
 * @Description Application URL Constants
 */
export class APIURLConstants {

    // Authentication API URL
    public static LOGIN_API_URL = '/core/login';
    public static LOGOUT_API_URL = '/core/logout';
    public static LOGGED_IN_USER_URL = '/core/loggedInUser';

    // Locale API URL
    public static GET_ALL_COUNTRIES_API_URL = '/core/get-countries';
    public static GET_ALL_CITIES_API_URL = '/core/get-cities';
    public static GET_CITIES_WITH_ID_API_URL = '/core/get-city-with-id';
    public static GET_BRANCH_COUNTRIES_API_URL = '/admin/branches-countries/';

    // Dashboard API URL
    public static DASHBOARD_API_URL = '/admin/dashboard';
    public static BRANCH_DASHBOARD_API_URL = '/serviceprovider/getBranchDashboardDetail/';

    // User API URL
    public static GET_PAGINATED_USER_API_URL = '/admin/admin-user/all/10?page=$page$';
    public static SEARCH_USER_API_URL = '/admin/admin-user/all/10?page=$page$';
    public static CREATE_USER_API_URL = '/core/register';
    public static GET_PAGINATED_USER_ACTIVITY_LOGS = '/admin/activity/all/10?page=$page$';
    public static GET_ADMIN_USER_BY_ID = '/admin/get-admin-user/$id$';
    public static GET_ADMIN_USER_DETAIL_BY_ID = '/admin/getAdminUserDetail/$id$';
    public static UPDATE_USER_API_URL = '/admin/update-admin-user';
    public static FORGOT_PASSWORD_API_URL = '/core/password/create';
    public static RESET_PASSWORD_API_URL = '/core/password/reset';
    public static VERIFY_TOKEN_EXPIRY_API_URL = '/core/password/verifyTokenExpiry';
    public static RESET_PASSWORD_OTP_API_URL = '/core/sendResetPasswordOTP';
    public static GET_USER_SEARCH_RESULT_API_URL = '/admin/autoCompleteUsers/';
    public static GET_USER_PERMISSIONS_API_URL = '/user/getAllPermissions';
    public static GET_ALL_ROLES_API_URL = '/user/getAllRoles';
    public static GET_ROLES_BY_ID_API_URL = '/user/getRolesByServiceProvider';
    public static GET_ADMIN_ROLE_API_URL = '/admin/role'; // /:Role/permissions (for role permissions by role)
    public static GET_ADMIN_ALL_ROLE_API_URL = '/admin/role/permissions'; // (for all role permissions) ?role=1 (to filter)
    public static GET_ALL_ADMIN_MENUS_API_URL = '/user/getAllAdminMenus';
    public static GET_ALL_ADMIN_ROLES_API_URL = '/user/getAllRoles/';
    public static DELETE_ACTIVITY_LOG_BY_ID_API_URL = '/admin/activity/delete/';
    public static DELETE_ALL_ACTIVITY_LOG_API_URL = '/admin/activity/delete-all';

    // Specialities API URL
    public static GET_ALL_SPECIALITIES_API_URL = '/admin/speciality/all/10?page=$page$';
    public static GET_ALL_SPECIALITIES_WITHOUT_PAGINATION_API_URL = '/admin/speciality/all/1000?page=$page$';
    public static GET_TOTAL_SPECIALITIES_API_URL = '/admin/speciality/all/1000?page=$page$';
    public static CREATE_SPECIALITY_API_URL = '/admin/speciality/add';
    public static GET_SPECIALITY_BY_ID_API_URL = '/admin/get-speciality/$id$';
    public static DELETE_SPECIALITY_BY_ID_API_URL = '/admin/speciality/delete/';
    public static UPDATE_SPECIALITY_BY_ID_API_URL = '/admin/speciality/update/';
    public static SEARCH_SPECIALITIES_API_URL = '/admin/speciality/all/10?page=$page$';
    public static GET_SPECIALITIES_MENU_API_URL = '/admin/speciality/all/1000?page=$page$';

    // change password
    public static CHANGE_PASSWORD_API_URL = '/user/change-password';
    public static CHANGE_PASSWORD_WITH_OTP_API_URL = '/user/changePasswordWithOTP';

    // Marketing API URL
    public static GET_ALL_EMAIL_TEMPLATE_API_URL = '/marketing/email-template/all/10?page=$page$';
    public static SEARCH_EMAIL_TEMPLATE_API_URL = '/marketing/email-template/all/10?page=$page$';
    public static CREATE_EMAIL_TEMPLATE_API_URL = '/marketing/email-template/create';
    public static UPDATE_EMAIL_TEMPLATE_API_URL = '/marketing/email-template/update/';
    public static GET_ALL_SMS_TEMPLATE_API_URL = '/marketing/sms-template/all/10?page=$page$';
    public static SEARCH_SMS_TEMPLATE_API_URL = '/marketing/sms-template/all/10?page=$page$';
    public static CREATE_SMS_TEMPLATE_API_URL = '/marketing/sms-template/create';
    public static UPDATE_SMS_TEMPLATE_API_URL = '/marketing/sms-template/update/';
    public static GET_ADD_YOUR_CLINIC_API_URL = '/marketing/getStaticTabsData/add_clinic/';
    public static GET_MEDICTA_WAY_API_URL = '/marketing/getStaticTabsData/medicta_way/';
    public static UPDATE_ADD_YOUR_CLINIC_API_URL = '/marketing/add-clinic/update';
    public static UPDATE_MEDICTA_WAY_API_URL = '/marketing/medicta-way/update';
    public static GET_NOTIFICATION_API_URL = '/marketing/custom-notification/all/10?page=$page$';
    public static SEARCH_NOTIFICATION_API_URL = '/marketing/custom-notification/all/10?page=$page$';
    public static ADD_NOTIFICATION_API_URL = '/marketing/custom-notification';
    public static UPDATE_NOTIFICATION_API_URL = '/marketing/custom-notification';
    public static DELETE_NOTIFICATION_API_URL = '/marketing/custom-notification';
    public static GET_EMAIL_SEARCH_RESULT_API_URL = '/marketing/autoCompleteEmailTemplate/';
    public static GET_SMS_SEARCH_RESULT_API_URL = '/marketing/autoCompleteSmsTemplate/';
    public static GET_NOTIFICATION_SEARCH_RESULT_API_URL = '/marketing/autoCompleteNotification/';

    public static GET_VARIABLE_API_URL = '/marketing/dynamic-variable/all';

    // settings
    public static GET_VAT_API_URL = '/admin/getVat';
    public static UPDATE_VAT_API_URL = '/admin/updateVat';
    public static GET_ALL_LINKS_API_URL = '/admin/getAllLinks';
    public static UPDATE_LINKS_API_URL = '/admin/updateLink/';
    public static ADD_LINKS_API_URL = '/admin/addLink';
    public static GET_SP_OFFERS_API_URL = '/serviceprovider/getServiceProvidersByOffers';
    public static GET_SPECIALITY_OFFERS_API_URL = '/admin/getOffersSpeciality';
    public static UPDATE_OFFERS_API_URL = '/admin/updateOffers';
    public static GET_SORT_BY_API_URL = '/admin/getSortBy';
    public static SET_SORT_BY_API_URL = '/admin/setSortBy/';
    public static UPDATE_SORT_BY_API_URL = '/admin/updateSortBy';
    public static GET_STATISTICS_API_URL = '/admin/getStatistics';
    public static UPDATE_STATISTICS_API_URL = '/admin/updateStatistics';
    public static UPDATE_MENU_API_URL = '/admin/updateMenu';
    public static GET_MENU_API_URL = '/admin/getMenus';
    public static UPDATE_CONTACT_INFO = '/admin/updateContactInfo';
    public static GET_COMMISSIONS_API_URL = '/admin/commission/all/10?page=$page$';
    public static CREATE_COMMISSION_API_URL = '/admin/createCommission ';
    public static UPDATE_COMMISSION_API_URL = '/admin/updateCommission/';
    public static UPDATE_MULTIPLE_COMMISSIONS_API_URL = '/admin/updateCommissions';
    public static GET_DEFAULT_COMMISSION_INFO = '/admin/getDefaultCommission ';
    public static GET_ALL_BRANCHES_API_URL = '/admin/allBranches ';
    public static GET_RESERVATION_CONDITIONS_API_URL = '/admin/reservation/conditions ';
    public static UPDATE_RESERVATION_CONDITIONS_API_URL = '/admin/reservation/conditions-update/1 ';

    // medical services
    public static GET_ALL_MEDICAL_SERVICES_API_URL = '/admin/medicalService/all/10?page=$page$';
    public static GET_ALL_MEDICAL_SERVICES_WITHOUT_PAGINATION_API_URL = '/admin/medicalService/all/1000?page=$page$';
    public static SEARCH_MEDICAL_SERVICES_API_URL = '/admin/medicalService/all/10?page=$page$';
    public static GET_TYPE_WISE_SPECIALITIES_API_URL = '/admin/getTypeWiseSpecialities';
    public static ADD_MEDICAL_SERVICE_API_URL = '/admin/medicalService/add';
    public static UPDATE_MEDICAL_SERVICE_API_URL = '/admin/medicalService/update';
    public static GET_SPECIALITY_SERVICE_API_URL = '/admin/getMedicalService';
    public static GET_SP_SPECIALITY_SERVICE_API_URL = '/admin/getSPMedicalService';
    public static GET_SPECIALITY_BRANCHES_API_URL = '/admin/getSpecialityBranches';
    public static GET_SP_MEDICAL_SERVICES_LISTING_API_URL = '/admin/getSpMedicalServicesListing';
    public static GET_SP_MEDICAL_SPECIALITY_LISTING_API_URL = '/admin/getSpMedicalSpecialityListing';
    public static DELETE_SP_MEDICAL_SERVICES_BY_ID_API_URL = '/admin/deleteSpMedicalServicesById';
    public static GET_MEDICAL_SERVICES_DETAIL_BY_ID_API_URL = '/admin/getMedicalServiceById/';
    public static GET_MEDICAL_SERVICE_DETAIL_API_URL = '/admin/getMedicalServiceDetails/';
    public static UPDATE_MEDICAL_SERVICE_STATUS_API_URL = '/admin/updateDoctorServicesStatus/';
    public static UPDATE_DOCTOR_SERVICE_STATUS_API_URL = '/admin/spMedicalService/updateStatus/';
    public static DELETE_MEDICAL_SERVICE_API_URL = '/admin/delete-medical-service/';
    public static GET_BRANCH_MEDICAL_SERVICES_BY_SPECIALITY_ID_API_URL = '/admin/getBranchMedicalServicesBySpecialityId';

    // electronic gate
    public static GET_ABOUT_MEDICTA_API_URL = '/admin/getAboutMedicta';
    public static UPDATE_ABOUT_MEDICTA_API_URL = '/admin/updateAboutMedicta';
    public static GET_TERM_AND_CONDITION_API_URL = '/admin/getTermsAndCondition';
    public static UPDATE_TERM_AND_CONDITION_API_URL = '/admin/updateTermsAndCondition';
    public static GET_PRIVACY_POLICAY_API_URL = '/admin/getPrivacyPolicy';
    public static UPDATE_PRIVACY_POLICY_API_URL = '/admin/updatePrivacyPolicy';
    public static GET_CANCELLATION_API_URL = '/admin/getReservationAndCancellationPolicy';
    public static UPDATE_CANCELLATION_API_URL = '/admin/updateReservationAndCancellationPolicy';
    public static GET_PAYMENT_METHOD_API_URL = '/admin/getPaymentMethods';
    public static UPDATE_PAYMENT_METHOD_API_URL = '/admin/updatePaymentMethods';
    public static ADD_PARTNER_API_URL = '/admin/addNewPartner';
    public static ADD_CONTRACT_API_URL = '/admin/addContract';
    public static UPDATE_PARTNER_API_URL = '/admin/updatePartner';
    public static UPDATE_CONTRACT_API_URL = '/admin/updateContract';
    public static GET_PARTNER_API_URL = '/admin/getPartners/10?page=$page$';
    public static GET_CONTRACTS_API_URL = '/admin/getContracts/10?page=$page$';
    public static DELETE_PARTNER_API_URL = '/admin/deletePartner';
    public static ADD_CAREER_API_URL = '/admin/addNewCareer';
    public static ADD_SECTION_API_URL = '/admin/sections/create';
    public static ADD_USER_MANUAL_API_URL = '/admin/addUserManual ';
    public static UPDATE_USER_MANUAL_API_URL = '/admin/updateUserManual';
    public static UPDATE_CAREER_API_URL = '/admin/updateCareer';
    public static GET_CAREER_API_URL = '/admin/getCareers/10?page=$page$';
    public static DELETE_CAREER_API_URL = '/admin/deleteCareer';
    public static GET_FAQ_API_URL = '/admin/getFAQ/all/10/type?page';
    public static GET_USER_MANUAL_API_URL = '/admin/getUserManual/all/10?page=$page$';
    public static DELETE_USER_MANUAL_API_URL = '/admin/deleteUserManual';
    public static ADD_FAQ_API_URL = '/admin/addFAQ';
    public static UPDATE_FAQ_API_URL = '/admin/updateFAQ';
    public static DELETE_FAQ_API_URL = '/admin/deleteFAQ';
    public static GET_CONTACT_INFO_API_URL = '/admin/getContactInfo';
    public static GET_CONTENT_API_URL = '/admin/getNewContent/10/type?page';
    public static ADD_CONTENT_API_URL = '/admin/addNewContent';
    public static UPDATE_CONTENT_API_URL = '/admin/updateContent/';
    public static DELETE_CONTENT_API_URL = '/admin/deleteContent/';

    // Help and Support
    public static GET_CONTACTS_API_URL = '/admin/contactus/all/10?page=$page$';
    public static GET_TICKETS_API_URL = '/admin/ticket/listing/all/10?page=$page$';
    public static SEARCH_TICKETS_API_URL = '/admin/ticket/listing/all/10?page=$page$';
    public static GET_OPEN_TICKETS_API_URL = '/admin/ticket/listing/open/10?page=$page$';
    public static GET_CLOSE_TICKETS_API_URL = '/admin/ticket/listing/close/10?page=$page$';
    public static ADD_TICKETS_API_URL = '/admin/tickets';
    public static GET_TICKET_BY_ID_API_URL = '/admin/getTicket';
    public static CLOSE_TICKET_API_URL = '/admin/updateTicket';
    public static UPDATE_TICKET_API_URL = '/admin/ticket/update';
    public static GET_SECTION_API_URL = '/admin/sections/all';

    // Service Provider Dashboard
    public static SP_DASHBOARD_API_URL = '/serviceprovider/dashboard';
    public static UPDATE_SP_STATUS_API_URL = '/serviceprovider/updateSpStatus/';
    public static GET_SP_DETAIL_API_URL = '/serviceprovider/getSpDetails';
    public static GET_SP_DETAIL_BY_ID_API_URL = '/serviceprovider/getServiceProvider/';

    // Branches
    public static GET_SERVICE_PROVIDER_TYPE_API_URL = '/serviceprovider/getServiceProviderTypes';
    public static CREATE_BRANCH_BASIC_INFO_API_URL = '/admin/createBranch';
    public static UPDATE_BRANCH_BASIC_INFO_API_URL = '/admin/updateBranchBasic';
    public static GET_NEIGHBORHOOD_BRANCH_API_URL = '/admin/getNeighborhoodBranch';
    public static CREATE_BRANCH_GENERAL_INFO_API_URL = '/admin/createBranchGeneral';
    public static CREATE_BRANCH_SPECIALITIES_API_URL = '/admin/createBranchSpecialities';
    public static CREATE_BRANCH_MEICAL_SERVICE_API_URL = '/admin/createMedicalService';
    public static CREATE_BRANCH_PAYMENT_METHOD_API_URL = '/admin/addBranchPaymentMethod';
    public static CREATE_CONTACT_API_URL = '/admin/createContact';
    public static GET_CONTACT_API_URL = '/admin/getContacts';
    public static DELETE_CONTACT_API_URL = '/admin/deleteContact';
    public static UPDATE_CONTACT_API_URL = '/admin/updateContact';
    public static GET_SPECIALITY_BY_BRANCH_ID_API_URL = '/admin/getSpecialitiesByBranchId';
    public static GET_BRANCH_BY_SP_API_URL = '/admin/getBranchesBySP';
    public static GET_BRANCH_DETAIL_BY_ID_URL = '/admin/getBranchDetailById';
    public static GET_PAGINATED_BRANCHES_API_URL = '/admin/branches/all/10/$spId$?page=$page$';
    public static UPDATE_BRANCH_STATUS_URL = '/admin/updateBranchStatus';
    public static UPDATE_BRANCH_V1_STATUS_URL = '/admin/updateBranchStatusV1';
    public static UPDATE_BRANCH_PERMISSION_URL = '/admin/updateBranchPermission';
    public static GET_BRANCH_RESERVATIONS_API_URL = '/admin/branchReservationListing/10';
    public static GET_BRANCH_REVIEWS_BY_ID_API_ULR = '/admin/getBranchReviews/';

    // Doctors
    public static GET_POSITION_TITLE_API_URL = '/serviceprovider/getPositionTitles';
    public static GET_DOCTOR_TITLE_API_URL = '/admin/getDoctorTitles';
    public static GET_SP_SPECIFIC_DOCTOR_TITLE_API_URL = '/admin/getSpSpecificDoctorTitles';
    public static GET_DOCTOR_NATIONALITY_API_URL = '/admin/getDoctorNationalities';
    public static GET_DOCTOR_TREATMENT_API_URL = '/serviceprovider/getTreatments';
    public static GET_SP_BRANCHES_API_URL = '/admin/getBranchesBySP';
    public static CREATE_DOCTOR_API_URL = '/admin/createDoctor';
    public static GET_DOCTORS_SP_API_URL = '/admin/spDoctors/list/10/$spId$?page=$page$';
    public static GET_DOCTORS_API_URL = '/admin/doctors/all/10/$spId$?page=$page$';
    // tslint:disable-next-line:variable-name
    public static GET_All_DOCTORS_API_URL = '/admin/doctors/all/1000/$spId$?page=$page$';
    public static GET_DOCTOR_SERVICES_API_URL = '/admin/doctorServices/all/10/$spId$?page=$page$';
    public static DELETE_DOCTOR_SERVICE_API_URL = '/admin/deleteDoctorService';
    public static GET_DOCTOR_DETAIL_API_URL = '/admin/getDoctorDetails';
    public static GET_DOCTOR_BRANCHES_API_URL = '/admin/getDoctorBranches';
    public static UPDATE_DOCTOR_API_URL = '/admin/updateDoctor';
    public static GET_DOCTOR_SPECIALITIES_BY_BRANCH_API_URL = '/admin/getDoctorSpecialities';
    public static GET_DOCTOR_SERVICE_BY_BRANCH_AND_SPECIALITY_API_URL = '/admin/getDoctorServices';
    public static GET_LANGUAGES_API_URL = '/serviceprovider/getLanguages';
    public static GET_DOCTOR_RESERVATIONS_API_URL = '/admin/doctorReservationListing/$status$/10';
    public static UPDATE_DOCTOR_MEDICAL_SERVICES_API_URL = '/admin/updateDoctorServices/';
    public static GET_DOCTOR_RATINGS_BY_DOC_ID_API_URL = '/admin/getDoctorRatings/10/$docId$?page=$page$&dateFrom=$dateFrom$&dateTo=$dateTo$';
    public static UPDATE_DOCTOR_STATUS_URL = '/admin/updateDoctorStatus';

    // Doctor Specialities
    public static GET_BRANCH_SPECIALITY_API_URL = '/admin/getSpecialitiesByBranchId';
    public static CREATE_DOCTOR_CONSULTANCY_API_URL = '/admin/createDoctorConsulting';
    public static UPDATE_DOCTOR_CONSULTANCY_API_URL = '/admin/updateDoctorConsulting/';
    public static CREATE_DOCTOR_SERVICE_API_URL = '/admin/createDoctorServices';
    public static GET_DOCTOR_BY_SP_API_URL = '/admin/getDoctorsByServiceProvider';

    // Doctor Schedule
    public static GET_FILTERS_BY_DOCTOR_API_URL = '/admin/getFiltersByDoctorId';
    public static CREATE_DOCTOR_SCHEDULE_API_URL = '/admin/createDoctorSchedule';
    public static VIEW_DOCTOR_SCHEDULE_API_URL = '/admin/viewDoctorDetailsById/';
    public static DELETE_DOCTOR_SCHEDULE_API_URL = '/admin/deleteDoctorDetailsById/';
    public static GET_DOCTOR_SCHEDULES_API_URL = '/admin/doctorScheduleListing/all/10/$spId$?page=$page$';
    public static GET_SHOW_DOCTOR_SCHEDULE_API_URL = '/admin/showDoctorSchedule/';
    public static GET_SHOW_CENTER_SCHEDULE_API_URL = '/admin/showCenterSchedule/';
    public static EDIT_DOCTOR_SCHEDULE_API_URL = '/admin/editDoctorSchedule';

    // Center Schedule
    public static CREATE_SPECIALITIES_TYPE_AND_SP_WISE_API_URL = '/admin/getSpecialitiesBySpAndType';
    public static GET_BRANCHES_BY_SERVICE_API_URL = '/admin/getBranchesByServiceId';
    public static GET_BRANCHES_AND_SERVICES_BY_SPECIALITY_ID_API_URL = '/admin/getBranchesAndServicesBySpecialityId';
    public static CREATE_CENTER_SCHEDULE_API_URL = '/admin/createCenterSchedule';
    public static EDIT_CENTER_SCHEDULE_API_URL = '/admin/editCenterSchedule';
    public static CREATE_CENTER_SCHEDULE_V1_API_URL = '/admin/createCenterSchedule_V1';
    public static GET_CENTER_SCHEDULES_API_URL = '/admin/centerScheduleListing/all/10/$spId$?page=$page$';

    // offers
    public static GET_SPECIALITIES_BY_SP_API_URL = '/admin/getSpecililtiesBySP';
    public static CREATE_OFFER_API_URL = '/admin/createSPOffers';
    public static EDIT_OFFER_API_URL = '/admin/editSPOffers';
    public static GET_ALL_OFFERS_API_URL = '/admin/offers/all/10/$spId$?page=$page$';
    public static CREATE_RESERVATION_API_URL = '/admin/createReservations';
    public static EDIT_RESERVATION_API_URL = '/admin/editReservations';
    public static GET_OFFER_DETAIL_API_URL = '/admin/getOfferDetail';
    public static UPDATE_OFFER_STATUS_API_URL = '/admin/updateOfferStatus/';
    public static CREATE_SPECIAL_OFFER_API_URL = '/admin/createSpecialOffer';
    public static GET_SPECIAL_OFFER_API_URL = '/admin/specialOfferListing';
    public static GET_SHOW_OFFER_SCHEDULE_API_URL = '/admin/showOfferReservation/';
    public static GET_OFFER_RESERVATIONS_API_URL = '/admin/offerReservationListing/10/';
    public static GET_OFFER_REVIEWS_BY_ID_API_URL = '/admin/getOfferReviews/';
    public static SAVE_OFFER_DESIGN_REQUEST_SETTING_API_URL = '/admin/store-offer-design-request-settings';
    public static UPDATE_OFFER_DESIGN_REQUEST_SETTING_API_URL = '/admin/update-offer-design-request-setting/';
    public static GET_OFFER_DESIGN_REQUEST_SETTING_API_URL = '/admin/get-offer-design-request-setting/';

    // Service Provider
    public static GET_PAGINATED_SP_API_URL = '/admin/service_providers/all/10?page=$page$';
    public static GET_ALL_SP_API_URL = '/admin/service_providers/all/1000?page=$page$';

    // SP Medical Services
    public static GET_PAGINATED_SP_MEDICAL_SERVICES_API_URL = '/admin/spMedicalServices/all/10/$spId$?page=$page$';
    public static UPDATE_SP_MEDICAL_SERVICES_API_URL = '/admin/updateMedicalService/';
    public static GET_SP_MEDICAL_SERVICES_DETAIL_API_URL = '/admin/getSpMedicalServiceDetailById';
    public static DELETE_SP_MEDICAL_SERVICES_DETAIL_API_URL = '/admin/deleteSpMedicalServiceDetailById';
    public static GET_SP_MEDICAL_SERVICES_API_URL = '/admin/spMedicalServices/all/1000/$spId$?page=$page$';

    // SP Users
    public static GET_PAGINATED_SP_USERS_API_URL = '/admin/service_provider_users/all/10/$spId$?page=$page$';
    public static GET_SP_USERS_API_URL = '/admin/service_provider_users/all/1000/$spId$?page=$page$';
    public static GET_SP_USERS_POSITION_API_URL = '/user/getAllRoles';
    public static GET_SP_USER_SEARCH_RESULT_API_URL = '/admin/autoCompleteServiceProviderUsers';
    public static GET_SP_USER_DETAILS_API_URL = '/admin/getServiceProviderUserDetails';
    public static UPDATE_SP_USER_STATUS_API_URL = '/admin/updateUserStatus';
    public static UPDATE_SP_USER_PERMISSION_API_URL = '/admin/updateSpUserPermission';
    public static UPDATE_SP_USER_API_URL = '/admin/updateSpUser';
    public static CREATE_USER_ROLE_API_URL = '/user/createRole/';

    // Requests
    public static GET_PAGINATED_BRANCHES_REQUEST_API_URL = '/admin/allBranchesRequest/all/10?page=$page$';
    public static GET_PAGINATED_SP_BRANCHES_REQUEST_API_URL = '/admin/getAllBranchesListingBySP/all/$sp$/10?page=$page$';
    public static GET_PAGINATED_SP_OFFERS_REQUEST_API_URL = '/admin/getAllOfferListing/all/10?page=$page$';
    public static GET_PAGINATED_RATING_REQUEST_API_URL = '/admin/ratingrequests/all/10?page=$page$';
    public static APPROVED_RATING_REQUEST_API_URL = '/admin/ratingrequests/approval';
    public static GET_RATING_REQUEST_DETAIL_API_URL = "/admin/ratingrequestdetails/"
    public static GET_SP_OFFERS_REQUEST_DETAIL_API_URL = '/admin/getSPOfferApprovalDetail';
    public static SP_OFFERS_REQUEST_APPROVE_API_URL = '/admin/approveSPOffer';
    public static GET_PAGINATED_CLINICS_REQUEST_API_URL = '/admin/clinic/listing/all/10?page=$page$';
    public static GET_PAGINATED_DISTRICT_REQUEST_API_URL = '/admin/getDistrictsForApproval';
    public static APPROVE_DISTRICT_REQUEST_API_URL = '/admin/approveDistrict';

    public static UPDATE_CLINIC_REQUEST_STATUS_API_URL = '/admin/updateClinicStatus/$id$/$status$';
    public static GET_PAGINATED_SERVICE_REQUEST_API_URL = '/admin/getAllServiceListing/all/10?page=$page$';
    public static GET_PAGINATED_SPECIALTY_REQUEST_API_URL = '/admin/getAllSpecialtyListing/all/10?page=$page$';
    public static APPROVE_SPECIALTY_REQUEST_API_URL = '/admin/approveSpecialityRequest/';
    public static APPROVE_SERVICE_REQUEST_API_URL = '/admin/approveServiceRequest/';
    public static REFUSED_SERVICE_REQUEST_API_URL = '/admin/rejectServiceRequest/';
    public static UPDATE_SERVICE_REQUEST_API_URL = '/admin/medicalService/update/';
    public static APPROVE_BRANCH_REQUEST_API_URL = '/admin/approveRequestBranch/';
    public static APPROVE_BRANCH_STATUS_REQUEST_API_URL = '/admin/approveBranchRequest/';
    public static REFUSED_BRANCH_REQUEST_API_URL = '/admin/rejectRequestBranch/';
    public static GET_OFFER_DESIGN_REQUEST_API_URL = '/admin/getOfferDesignRequests/';

    // Customer
    public static GET_PAGINATED_CUSTOMER_API_URL = '/customer/customerListing/all/10?page=$page$';
    public static GET_ALL_CUSTOMER_API_URL = '/customer/customerListing/all/1000?page=$page$';
    public static GET_CUSTOMER_DETAILS_API_URL = '/customer/getCustomerDetail';
    public static SAVE_CUSTOMER_MEMBER_API_URL = '/customer/saveCustomerMember';
    public static DELETE_CUSTOMER_MEMBER_API_URL = '/customer/deleteMember';
    public static UPDATE_CUSTOMER_MEMBER_API_URL = '/customer/updateMember';
    public static UPDATE_USER_PROFILE_API_URL = '/customer/update-profile';
    public static UPDATE_USER_PROFILE_IMAGE_API_URL = '/customer/updateProfilePic/';
    public static GET_DASHBOARD_CUSTOMER_CHART_API_URL = '/customer/getDashboardCustomerCount'; // dashboard old customer chart api
    public static GET_ADMIN_CUSTOMER_CHART_API_URL = '/admin/stats/months/customers';
    public static GET_DASHBOARD_CLOSE_RESERVATIONS_API_URL = '/customer/getDashboardReservationCount'; // dashboard old reservation chart api
    public static GET_ADMIN_CLOSE_RESERVATIONS_API_URL = '/admin/stats/months/close-reservations';
    public static GET_UPCOMING_RESERVATIONS_API_URL = '/admin/getUpcomingReservations/';
    public static GET_PREVIOUS_RESERVATIONS_API_URL = '/admin/getPreviousReservations/';
    public static GET_FAVOURITE_DOCTORS_BY_CUSTOMER_ID = '/customer/getFavouriteDoctorsById/';
    public static GET_FAVOURITE_BRANCH_BY_CUSTOMER_ID = '/customer/getFavouriteBranchesByCustomer/';
    public static removeCustomerFavourite = '/customer/removeCustomerFavourite/';

    // reservation
    public static GET_RESERVATIONS_API_URL = '/admin/reservation/$status$/10?page=$page$';
    public static GET_RESERVATIONS_V1_API_URL = '/admin/reservationV1/$status$/10?page=$page$';
    public static GET_RESERVATIONS_EXPORT_API_URL = '/admin/reservationExport/$status$?';
    public static UPDATE_RESERVATION_STATUS_API_URL = '/admin/updateReservationStatus/';
    public static GET_RESERVATION_DETAIL_BY_ID_API_URL = '/admin/getReservationDetailById/';
    public static GET_DOCTOR_BY_BRANCH_SPECIALITY_ID_API_URL = '/admin/getDoctorsByBranchIdAndSpeciality/';
    public static UPDATE_RESERVATION_API_URL = '/admin/updateReservation/';

    // Financial API URL
    public static GET_SP_FINANCIAL_LISTING_API_URL = '/financial/sp/settlement/detail/list/10?page=$page$&from_date=$fromDate$' +
        '&to_date=$toDate$&sp_id=$sp_id$';
    public static GET_FINANCIAL_INVOICE_DETAIL_API_URL = '/financial/invoice/detail/';
    public static GET_FINANCIAL_INVOICE_RESERVATION_LISTING_API_URL = '/financial/invoice/reservation/listing/';
    public static GET_FINANCIAL_ALL_API_URL = '/financial/list/settlment/10?page=$page$&from_date=$fromDate$&to_date=$toDate$';
    public static NEW_GET_FINANCIAL_ALL_API_URL = '/financial/list/settlment/10?page=$page$&from_date=$fromDate$&to_date=$toDate$';
    public static SEARCH_FINANCIAL_ALL_API_UR = '/financial/listing/all/10?page=$page$';
    public static CREATE_BANK_ACCOUNT_API_URL = '/financial/saveBankAccount';
    public static UPDATE_BANK_ACCOUNT_API_URL = '/financial/updateBankAccount/';
    public static GET_FINANCIAL_CUSTOMER_SETTELMENT_API_URL = '/financial/customer/settlment/';
    public static GET_ALL_BANK_ACCOUNT_API_URL = '/financial/bank/all';
    public static GET_FINANCIAL_ENTRY_BY_ID_API_URL = '/financial/getFinancialEntryDetailById/';
    public static GET_ALL_FINANCIAL_BALANCE_BY_CUSTOMER_ID_API_URL = '/financial/customerFinancialListing/10/$customer_id$?page=$page$';
    public static CREATE_FINANCIAL_TRANSACTION_API_URL = '/financial/addTransaction';
    public static GET_FINANCIAL_SETTLEMENT_ALL_API_URL = '/financial/settlement/listing/all/10';
    public static GET_FINANCIAL_UNPAID_INVOICES_ALL_API_URL = '/financial/invoices/unpaid-listing';
    public static GET_FINANCIAL_UNPAID_INVOICES_DETAIL_ALL_API_URL = '/financial/consolidated/invoices/unpaid-listing/';
    public static GET_FINANCIAL_PAID_INVOICES_ALL_API_URL = '/financial/invoices/paid-listing';
    public static GET_FINANCIAL_INVOICE_DETAIL_DOWNLOAD_MODAL_API = '/financial/financial-detail/';
    public static GET_FINANCIAL_PAYMENT_API_URL = '/financial/invoices/payment/all';
    public static GET_FINANCIAL_PAYMENT_REQUEST_LISTING_API_URL = '/financial/paymentRequest/listing/all/10?page=$page$';
    public static GET_FINANCIAL_PAYMENT_REQUEST_DETAIL_BY_ID_API_URL = '/financial/paymentRequest/detail/';
    public static GET_FINANCIAL_SP_PAYMENT_REQUEST_DETAIL_BY_ID_API_URL = '/financial/sp/settlement/paymentinfo/detail/';
    public static GET_SP_FINANCIAL_SETTLEMENT_ALL_API_URL = '/financial/sp/settlement/listing/all/10';
    public static GET_SP_FINANCIAL_PREVIOUS_SETTLEMENT_ALL_API_URL = '/financial/sp/pre/settlement/listing/all/10';

    // Financial Payment Request API URL
    public static GET_FINANCIAL_ALL_PAYMENT_REQUEST_LISTING_URL = '/financial/paymentRequest/listing/all/10?page=$page$';
    public static GET_FINANCIAL_CUSTOMER_PAYMENT_REQUEST_LISTING_URL = '/financial/paymentRequest/listing/payment_request/10?page=$page$';
    public static GET_FINANCIAL_BANK_RE_PAYMENT_REQUEST_LISTING_URL = '/financial/paymentRequest/listing/bank_repayment/10?page=$page$';
    public static UPDATE_FINANCIAL_PAYMENT_REQUEST_URL = '/financial/updatePaymentRequest';

    // Financial Invoices
    public static GET_FINANCIAL_INVOICE_LISTING_URL = '/financial/invoices/listing';
    public static GET_FINANCIAL_INVOICE_LISTING_DETAIL_URL = '/financial/invoices/listingDetail/';
    public static GET_FINANCIAL_INVOICE_SUB_MENU_LISTING_DETAIL_URL = '/financial/invoices/details/listing/';
    public static GET_FINANCIAL_INVOICE_META_DETAIL_URL = '/financial/invoices/metaDetail/';


    // Push Notification
    public static CLEAR_ALL_NOTIFICATIONS = '/user/clearAllNotifications/';
    public static GET_ALL_NOTIFICATIONS = '/user/notifications/all/';
    public static GET_ALL_NOTIFICATIONS_COUNT = '/user/notifications/count/';
    public static GET_ALL_NOTIFICATIONS_SEE_ALL = '/user/notifications/listing/';

    // Reports API
    public static REPORTS_GET_ALL_FILTERS = '/report/filters/'
    public static REPORTS_GET_ALL_BRANCH = '/report/branches/'
    public static REPORTS_GET_ALL_DOCTORS = '/report/doctors/'
    public static REPORTS_GET_ALL_MS = '/report/medical-services/'
    public static REPORTS_GET_ALL_RESERVATION = '/report/reservations/'
    public static REPORTS_GET_ALL_OFFER = '/report/offers/'
    public static REPORTS_GET_ALL_FINANCIAL = '/report/financial/'
    public static REPORTS_GET_ALL_CUSTOMER = '/report/customer/'
    public static REPORTS_GET_ALL_RATINGS = '/report/ratings/'
    
    public static SETTING_CONSULTATION_CONDITION_UPDATE = '/admin/reservation/conditions-update/1'
    public static SETTING_CONSULTATION_CONDITION_GET = '/admin/reservation/conditions'
}
