import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {RequestsService} from '../service/requests.service';
import {AdminUtilService} from '../service/admin-util.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {APIURLConstants} from '../util/api.url.constants';
import {PlatformLocation} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../service/shared.service';

@Component({
    selector: 'login-component',
    templateUrl: '../templates/login.template.html'
})
export class LoginComponent implements OnInit, OnDestroy {

    username: string;
    password: string;
    rememberMe = false;
    forgotPasswordModal: boolean;
    fcmToken: any;
    loginForm: FormGroup;

    constructor(private requestsService: RequestsService,
                private router: Router,
                public adminUtilService: AdminUtilService,
                private elementRef: ElementRef,
                private plateFormLocation: PlatformLocation,
                private toastrService: ToastrService,
                public sharedService: SharedService,
                private translate: TranslateService,
                private formBuilder: FormBuilder) {
        translate.setDefaultLang(localStorage.getItem('selectedLanguage'));
    }

    ngOnInit() {
        if (window.localStorage.getItem(btoa('access_token'))) {
            this.router.navigate(['dashboard']);
        } else {
            this.router.navigate(['/login']);
            this.sharedService.fcmToken.subscribe((_token) => {
                this.fcmToken = _token;
            });
        }
        this._formBuilder();
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#dbeaca';
        this.setRememberedCredentials();
    }

    _formBuilder() {
        this.loginForm = this.formBuilder.group({
            user_name: ['', Validators.required],
            password: ['', Validators.required],
            remember_me: [null],
        });
    }

    ngOnDestroy() {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#fff';
    }

    setRememberedCredentials() {
        if (JSON.parse(localStorage.getItem('credentials')) !== null) {
            const credentials = JSON.parse(localStorage.getItem('credentials'));
            if (credentials) {
                this.loginForm.patchValue({
                    user_name: credentials.user_name,
                    password: credentials.password,
                    remember_me: credentials.remember_me
                });
            }
        }
    }

    onClickResetPassword(email) {
        if (email && email !== '') {
            this.forgotPasswordModal = false;
            const url = this.plateFormLocation['location'].origin + '/change-password';
            this.requestsService.postRequest(APIURLConstants.FORGOT_PASSWORD_API_URL, {email, url}).subscribe(
                (response: Response) => {
                    this.toastrService.info(response['message'], 'Reset Password Link');
                }
            );
        }
    }

    login() {
        if (this.loginForm.invalid) {
            this.toastrService.error('Fields are required.', 'Sign In');
            return;
        }
        this.requestsService.postRequestAccessToken(
            APIURLConstants.LOGIN_API_URL
            , {
                user_name: this.loginForm.value.user_name,
                password: this.loginForm.value.password,
                fcm_token: this.fcmToken,
                user_type: 'admin_users',
                device_type: 'web'
            })
            .subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'AUTH_SUC_01') {
                        this.isRememberMe(this.loginForm.value);
                        const obj = JSON.parse(JSON.stringify(response['responseData'].admin_users));
                        localStorage.setItem(btoa('access_token'), response['token']);
                        localStorage.setItem(btoa('user-info'), JSON.stringify(obj));
                        this.adminUtilService.userInfo = JSON.parse(JSON.stringify(response['responseData']));
                        this.router.navigate(['/dashboard']);
                    } else {
                        this.toastrService.error(response['responseMessage'], 'Sign In');
                    }
                }, (error: any) => {
                    this.adminUtilService.tokenExpired(error.status);
                });
    }

    changeLanguage(language: string) {
        this.translate.use(language);
    }

    forgotPassword() {
        this.forgotPasswordModal = true;
    }

    isRememberMe(values) {
        if (values.remember_me) {
            localStorage.setItem('credentials', JSON.stringify(values));
        } else {
            localStorage.removeItem('credentials');
        }
    }

}
