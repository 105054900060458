import {Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {BehaviorSubject} from 'rxjs';
import {SharedService} from './shared.service';
import {palegoldenrod} from 'color-name';

@Injectable()
export class MessagingService {
    currentMessage = new BehaviorSubject(null);

    constructor(private angularFireMessaging: AngularFireMessaging, private sharedService: SharedService) {
        this.angularFireMessaging.onMessage((payload) => {
        });
    }

    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                if (token) {
                    this.sharedService.fcmToken.next(token);
                }
            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }

    receiveMessage() {
        this.angularFireMessaging.onMessage(
            (payload) => {
                this.currentMessage.next(payload);
            });
    }
}
