import {Component, OnInit} from '@angular/core';
import {LoaderService} from '../service/loader.service';
import {AdminUtilService} from '../service/admin-util.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-menu',
    templateUrl: '../templates/app.menu.template.html'
})
export class AppMenuComponent implements OnInit {


    constructor(public loaderService: LoaderService,
                private translate: TranslateService,
                public adminUtilService: AdminUtilService) {
        translate.setDefaultLang(localStorage.getItem('selectedLanguage'));
    }

    ngOnInit() {

    }

    userHasPermissionTo(action: string, permissionPathName: string) {
        return this.adminUtilService.userHasPermissionTo(action, permissionPathName);
    }

}
