import {RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {LoginComponent} from './components/login.component';
import {ContentComponent} from './components/content.component';
import {ResourceNotFoundComponent} from './components/error/resource.not.found.component';
import {ChangePasswordComponent} from './components/change-password/change-password.component';
import {ActivateServiceProviderComponent} from './components/activate-service-provider/activate-service-provider.component';

export const routes: Routes = [
    {path: '', redirectTo: 'login', pathMatch: 'full'},
    {path: 'login', component: LoginComponent},
    {path: 'change-password/:id', component: ChangePasswordComponent},
    {path: 'activate-service-provider/:id', component: ActivateServiceProviderComponent},
    {path: 'not-found', component: ResourceNotFoundComponent},
    {
        path: 'dashboard', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/dashboard/dashboard.module#DashboardModule'},
        ]
    },
    {
        path: 'service-provider', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/service-provider/service.provider.module#ServiceProviderModule'},
        ]
    },
    {
        path: 'request', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/request/requests.module#RequestsModule'},
        ]
    },
    {
        path: 'speciality', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/specialities/speciality.module#SpecialityModule'},
        ]
    },
    {
        path: 'medical-service', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/medical-service/medical.service.module#MedicalServiceModule'},
        ]
    },
    {
        path: 'user', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/user/user.module#UserModule'},
        ]
    },
    {
        path: 'my-account', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/my-account/my.account.module#MyAccountModule'},
        ]
    },
    {
        path: 'reservation', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/reservation/reservation.module#ReservationModule'},
        ]
    },
    {
        path: 'financial', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/financial/financial.module#FinancialModule'},
        ]
    },
    {
        path: 'reports', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/reports/reports.module#ReportsModule'},
        ]
    },
    {
        path: 'customer', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/customer/customer.module#CustomerModule'},
        ]
    },
    {
        path: 'settings', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/settings/settings.module#SettingsModule'},
        ]
    },
    {
        path: 'marketing', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/marketing/marketing.module#MarketingModule'},
        ]
    },
    {
        path: 'electronic-gate', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/electronic-gate/electronic.gate.module#ElectronicGateModule'},
        ]
    },
    {
        path: 'help-support', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/help-support/help.support.module#HelpSupportModule'},
        ]
    },
];

export const AppRoutingModule: ModuleWithProviders =
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'});
