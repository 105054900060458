import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Location } from '@angular/common';
import { PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { APIURLConstants } from "../util/api.url.constants";
import { RequestsService } from "./requests.service";
import { AdminUtilService } from "./admin-util.service";

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';

@Injectable()
export class SharedService {
    get allPositionPermissions(): any {
        return this._allPositionPermissions.asObservable();
    }

    set allPositionPermissions(value: any) {
        this._allPositionPermissions.next(value);
    }

    get userPositions() {
        return this._userPositions.asObservable();
    }

    set userPositions(value: any) {
        this._userPositions.next(value);
    }

    private _userPositions: Subject<any> = new Subject();
    private _allPositionPermissions: Subject<any> = new Subject();

    constructor(private router: Router,
        private adminUtilService: AdminUtilService,
        private requestsService: RequestsService) {
        const currentState = JSON.parse(localStorage.getItem('currentState'));
        if (currentState && currentState.header) {
            this.serviceProviderMenu.next(currentState.header);
        }
        this.loadUserPositions();
        this.getAllRolePermissions();
    }

    isUserLoggedIn = false;
    userLoggedIn: Subject<boolean> = new Subject();

    private NotificationTabEvent = new BehaviorSubject<boolean>(false);
    currentNotificationTabStatus = this.NotificationTabEvent.asObservable();
    public medicalServiceData = {};
    public fcmToken = new Subject();
    public serviceProviderMenu = new BehaviorSubject<string>('');
    public userDisabled = new Subject<any>();
    public browserBackButtonPressed = new Subject<any>();

    getMenu(): Observable<any> {
        return this.serviceProviderMenu.asObservable();
    }

    setMenu(value) {
        this.serviceProviderMenu.next(value);
        const currentState = { header: value };
        localStorage.setItem('currentState', JSON.stringify(currentState));
    }

    setNotificationTabStatus(val) {
        this.NotificationTabEvent.next(val);
    }

    getUrlSegment() {
        const tree: UrlTree = this.router.parseUrl(this.router.url);
        const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        const s: UrlSegment[] = g.segments;
        return s;
    }

    getPreviousPath(endPath) {
        return (this.router.url.split(endPath)).join('');
    }

    getIdFromUrl(segment) {
        const path = this.router.url.split(segment).pop();
        return path.split('/')[1];
    }

    getActiveTab() {
        const path = this.router.url.split('/');
        return path[path.length - 1];
    }

    forcefullyLogoutDisabledUser(): Observable<any> {
        return this.userDisabled.asObservable();
    }

    onBrowserBackButtonPress(): Observable<any> {
        return this.browserBackButtonPressed.asObservable();
    }

    loadUserPositions() {
        this.requestsService.getRequest(
            APIURLConstants.GET_ALL_ADMIN_ROLES_API_URL + 'admin_user')
            .subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'ROLES_SUC_01') {
                        this.userPositions = response['responseData'];
                    } else {
                        this.userPositions = [];
                    }
                },
                (error: any) => {
                    this.adminUtilService.tokenExpired(error.status);
                });
    }

    getAllRolePermissions() {
        this.requestsService.getRequest(APIURLConstants.GET_ADMIN_ALL_ROLE_API_URL).subscribe(
            (response: Response) => {
                if (response['responseCode'] === 'PER_SUC_02') {
                    this.allPositionPermissions = response['responseData'];
                } else {
                    this.allPositionPermissions = {};
                }
            },
            (error: any) => {
                this.adminUtilService.tokenExpired(error.status);
            });
    }

    getActiveLanguage() {
        return localStorage.getItem('selectedLanguage');
    }

    getconfirmationfortask() {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(true);
            }, 1000);
        });
    }

    exportpdf(devid) {
        const doc = new jsPDF()
        autoTable(doc, { html: '#' + devid })
        doc.save(devid + '.pdf')
    }

    exportexcel(devid): void {
        /* table id is passed over here */
        let element = document.getElementById(devid);
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, devid + '.xlsx');

    }

    exportprint(devid) {
        const doc = new jsPDF()
        autoTable(doc, { html: '#' + devid })
        doc.save(devid + '.pdf')
    }

}

